import { combineReducers } from 'redux';
import { HYDRATE } from 'next-redux-wrapper';
import * as Sentry from '@sentry/nextjs';

import activities from './activities';
import modal from './modal';
import questionnaire from './questionnaire';
import programs from './programs';
import user from './user';
import workout from './workout';
import sessions from './sessions';
import exercises from './exercises';
import feedback from './feedback';
import achievements from './achievements';
import toasts from './toasts';
import ponder from './ponder';
import advertisement from './advertisement';

const appReducer = combineReducers({
  activities,
  advertisement,
  questionnaire,
  modal,
  user,
  programs,
  workout,
  sessions,
  exercises,
  feedback,
  achievements,
  toasts,
  ponder,
});

const rootState = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
      modal: state.modal,
      exercises: state.exercises,
      activities: state.activities,
    };
    return nextState;
  }

  try {
    return appReducer(state, action);
  } catch (err) {
    Sentry.captureException(err);
    return appReducer(state, action);
  }
};

export default rootState;
