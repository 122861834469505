import { takeLatest, all } from 'redux-saga/effects';
import { FETCH_ACTIVITIES } from 'actions/activities';
import { USER_ACTION_TYPES } from 'actions/user';
import {
  PROGRAMS_ACTION_TYPES,
  SCHEDULE_ACTION_TYPES,
  PROGRAM_SCHEDULE_ACTION_TYPES,
} from 'actions/programs';
import { CREATE_FEEDBACK } from '../actions/feedback';
import { FETCH_ACHIEVEMENTS } from '../actions/achievements';
import { FETCH_ERROR_HANDLER } from '../actions/fetchErrorHandler';
import { ADVERTISEMENT_PRODUCT } from '../actions/advertisement';
import {
  FETCH_QUESTIONNAIRE,
  SEND_QUESTIONNAIRE_ANSWERS,
  FETCH_QUESTIONNAIRE_ANSWERS,
} from '../actions/questionnaire';
import {
  FETCH_PROGRAM_SESSIONS,
  FETCH_PROGRAM_SESSION_BY_ID,
  FETCH_EXPANDED_SESSION_INFO,
} from '../actions/sessions';
import {
  GET_CUSTOMER_PORTAL_URL,
  GET_PRODUCT_DATA,
  REDIRECT_TO_STRIPE_CHECKOUT,
} from '../actions/products';
import {
  FETCH_SESSION_EXERCISES_LIST,
  FETCH_SIGN_EXERCISE_VIDEO,
  FETCH_EXERCISE_BY_ID,
  COMPLETE_EXERCISE,
} from '../actions/exercise';
import {
  FETCH_WORKOUT_SESSIONS,
  FETCH_WORKOUT_SESSIONS_PROGRAM,
  FETCH_WORKOUT_SESSION_BY_ID,
  FETCH_WORKOUT_SESSION,
  CREATE_WORKOUT,
  CREATE_PREPARE_PHASE_ANSWER,
  DONE_WORKOUT,
  DELETE_WORKOUT,
  FETCH_RESUME_WORKOUT,
  START_EXERCISE,
  UPDATE_WORKOUT_SURVEY,
} from '../actions/workout';
import createFeedback from './feedback';
import fetchAchievements from './achievements';
import fetchErrorHandler from './fetchErrorHandler';
import fetchAdvertisementProduct from './advertisement';
import { openCustomerPortal, fetchPurchasedProduct, redirectToStripeCheckout } from './products';
import {
  fetchQuestionnaire,
  fetchUserQuestionnaireAnswers,
  sendQuestionnaireAnswers,
} from './questionnaire';
import {
  fetchProgramSessions,
  fetchProgramSessionById,
  fetchExpandedSessionInfo,
} from './sessions';
import {
  fetchSessionExercisesList,
  fetchSignCrExerciseVideo,
  startExercise,
  fetchExerciseById,
  completeExercise,
} from './exercises';
import { fetchUser, updateUser } from './user';
import {
  fetchUserWorkoutSessions,
  fetchProgramWorkoutSession,
  fetchUserWorkoutSessionById,
  createUserWorkout,
  createPreparePhaseAnswer,
  doneWorkout,
  deleteUserWorkout,
  updateWorkoutSurvey,
  fetchResumeWorkout,
  fetchWorkoutSession,
} from './workout';
import fetchActivities from './activities';
import { fetchPrograms, updateProgramsSchedule, updateProgramSchedule } from './programs';

export default function* root() {
  yield all([
    takeLatest(FETCH_QUESTIONNAIRE.request, fetchQuestionnaire),
    takeLatest(SEND_QUESTIONNAIRE_ANSWERS.request, sendQuestionnaireAnswers),
    takeLatest(USER_ACTION_TYPES.REQUEST, fetchUser),
    takeLatest(USER_ACTION_TYPES.UPDATE_REQUEST, updateUser),
    takeLatest(FETCH_ACTIVITIES.request, fetchActivities),
    takeLatest(PROGRAMS_ACTION_TYPES.LOADING, fetchPrograms),
    takeLatest(SCHEDULE_ACTION_TYPES.UPDATING, updateProgramsSchedule),
    takeLatest(FETCH_WORKOUT_SESSIONS.request, fetchUserWorkoutSessions),
    takeLatest(FETCH_QUESTIONNAIRE_ANSWERS.request, fetchUserQuestionnaireAnswers),
    takeLatest(FETCH_WORKOUT_SESSIONS_PROGRAM.request, fetchProgramWorkoutSession),
    takeLatest(FETCH_PROGRAM_SESSIONS.request, fetchProgramSessions),
    takeLatest(FETCH_PROGRAM_SESSION_BY_ID.request, fetchProgramSessionById),
    takeLatest(FETCH_WORKOUT_SESSION_BY_ID.request, fetchUserWorkoutSessionById),
    takeLatest(CREATE_WORKOUT.request, createUserWorkout),
    takeLatest(FETCH_SESSION_EXERCISES_LIST.request, fetchSessionExercisesList),
    takeLatest(FETCH_SIGN_EXERCISE_VIDEO.request, fetchSignCrExerciseVideo),
    takeLatest(START_EXERCISE.request, startExercise),
    takeLatest(CREATE_FEEDBACK.request, createFeedback),
    takeLatest(FETCH_EXERCISE_BY_ID.request, fetchExerciseById),
    takeLatest(CREATE_PREPARE_PHASE_ANSWER.request, createPreparePhaseAnswer),
    takeLatest(DONE_WORKOUT.request, doneWorkout),
    takeLatest(COMPLETE_EXERCISE.request, completeExercise),
    takeLatest(DELETE_WORKOUT.request, deleteUserWorkout),
    takeLatest(UPDATE_WORKOUT_SURVEY.request, updateWorkoutSurvey),
    takeLatest(FETCH_ACHIEVEMENTS.request, fetchAchievements),
    takeLatest(FETCH_RESUME_WORKOUT.request, fetchResumeWorkout),
    takeLatest(FETCH_WORKOUT_SESSION.request, fetchWorkoutSession),
    takeLatest(REDIRECT_TO_STRIPE_CHECKOUT.request, redirectToStripeCheckout),
    takeLatest(GET_PRODUCT_DATA.request, fetchPurchasedProduct),
    takeLatest(GET_CUSTOMER_PORTAL_URL.request, openCustomerPortal),
    takeLatest(FETCH_ERROR_HANDLER, fetchErrorHandler),
    takeLatest(ADVERTISEMENT_PRODUCT.request, fetchAdvertisementProduct),
    takeLatest(FETCH_EXPANDED_SESSION_INFO.request, fetchExpandedSessionInfo),
    takeLatest(PROGRAM_SCHEDULE_ACTION_TYPES.UPDATING, updateProgramSchedule),
  ]);
}
